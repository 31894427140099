import '@fontsource/roboto/latin.css';
import GoogleTagManager from 'components/GoogleTagManager';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import 'styles/Calendar/calendar-override.css';
import 'styles/Calendar/mobiscroll.react.min.css';
import 'styles/globals.css';
import primaryTheme from 'styles/themes/primary.json';

const Container = styled.div`
	margin: auto;
	padding: 0 16px;
	@media (min-width: ${(props) => props.theme.breakpoints.medium}) {
	}
	@media (min-width: ${(props) => props.theme.breakpoints.large}) {
		max-width: 960px;
	}
	@media (min-width: ${(props) => props.theme.breakpoints.x_large}) {
		max-width: 1140px;
	}
	@media (min-width: ${(props) => props.theme.breakpoints.xx_large}) {
		max-width: 1360px;
	}
	@media (min-width: 1440px) {
		max-width: 1360px;
		padding: 0 32px;
	}
`;

const MyApp = ({ Component, pageProps }: AppProps) => (
	<RecoilRoot>
		<GoogleTagManager>
			<ThemeProvider theme={primaryTheme}>
				<main>
					<Container>
						<Component {...pageProps} />
					</Container>
				</main>
			</ThemeProvider>
		</GoogleTagManager>
	</RecoilRoot>
);

export default MyApp;
